// import React from 'react';  
// import './Features.css';
// import features from './Feature.json'

// export default function Features() {
 

//   return (
//   <div>
//     <div className='features_c'>
//       < section className='feat_ban'>
//         <div className='feat_ban_txt'>
//           <div><h1>Most Advance World Class Accounting Software</h1>
//           <br/>
//           <p>
//           Experience unparalleled financial management with our cutting-edge accounting software, setting the standard for world-class solutions. Streamline your business operations effortlessly, utilizing advanced features that ensure accuracy, efficiency, and compliance. Benefit from real-time insights into your financial health, empowering informed decision-making. Our software's user-friendly interface and robust capabilities cater to the diverse needs of businesses, making it a top choice for professionals seeking excellence in accounting technology. Elevate your financial management to new heights with the most advanced and globally recognized accounting software on the market
//           </p>
//           </div>
//         <div><center> <img className='ban_img' src='/images/Accounting.svg'/> </center></div>  
//               </div> 
//       </section>
//       <br/>
//     <div className="featu_main">
//  <center>
//  <div className="feat_wrap">
//       {features.map((feature) => (
//         <div className="feat_coll" key={feature.id}>
//           <input type="checkbox" id={feature.id} />
//           <label htmlFor={feature.id}>{feature.title}</label>
//           <div className="feat_coll_text">
//             <img className='feat_img' src={feature.imageSrc} />
//             <p>{feature.content}</p><br/>
//           </div>
//         </div>
//       ))}
//     </div>
//  </center>
//   </div>
//     </div>
//   </div>
//   );
// }



import React from 'react';  
import './Features.css';
import featureData from './Feature.json'

export default function Features() {
 

  return (
  <div>
    <div className='features_c'>
      <section className='feat_ban'>
        <div className='feat_ban_txt'>
          <div><h1>World Class Accounting Software</h1>
          <br/>
          <p>
          Experience unparalleled financial management with our cutting-edge accounting software, setting the standard for world-class solutions. Streamline your business operations effortlessly, utilizing advanced features that ensure accuracy, efficiency, and compliance. Benefit from real-time insights into your financial health, empowering informed decision-making. Our software's user-friendly interface and robust capabilities cater to the diverse needs of businesses, making it a top choice for professionals seeking excellence in accounting technology. Elevate your financial management to new heights with the most advanced and globally recognized accounting software on the market
          </p>
          </div>
        <div><center> <img alt='Banner_image' className='ban_img' src='/images/Accounting.svg'/> </center></div>  
              </div> 
      </section>
      <center><h1>Features</h1></center>
      <div className="featu_main">
        <center>
          <div className="feat_wrap">
            {featureData.map((feature) => {
              if (feature.side === '1') {
                return (
                  <div key={feature.id} className={`feat_coll side_1`}>
                    <div className="feat_lab">
                      <img className="feat_img" src={feature.imageSrc} alt={feature.title} />
                    </div>
                    <div className="feat_coll_text">
                      <label className='fe_tit'>{feature.title}</label>
                      <p className='fe_dec'>{feature.content}</p>
                      <br />
                    </div>
                  </div>
                );
              } else {
                // Render content for side 2
                return (
                  <div key={feature.id} className={`feat_coll side_2`}>
                    <div className="feat_coll_text">
                    <label className='fe_tit'>{feature.title}</label>
                    <p className='fe_dec'>{feature.content}</p>
                      <br />
                    </div>
                    <div className="feat_lab">
                      <img className="feat_img" src={feature.imageSrc} alt={feature.title} />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </center>
      </div>
    </div>
  </div>
  );
}

